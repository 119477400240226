@import '../vars.scss';

#page-404 {
  background-color: $white1;
  
  .section-404 {
    margin: 7rem 0px;
    .header {
      color: $black1;
      max-width: 600px;
    }
    .subheader {
      color: $black1;
      max-width: 850px;
    }
  }
}